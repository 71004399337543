import React from "react";

/**
 * display message window to user
 * @param isError 
 * @param errorNum 
 * @returns 
 */
export function customMessageWindow(isError: undefined, errorNum: number) {

  if(isError != undefined) return (<></>);

  const batsu = "\u{00d7}" // → ×
  let msg: string;
  switch(errorNum) {
    case 10:
      msg = "failed to get \"ADDRESS\" info";
      break;
    case 11:
      msg = "failed to get \"CARD\" info";
      break;
  }
  
  return (
    <>
      <div id="error_message" className="z-10 animate-fadeIn_02 fixed top-0 right-0 w-full md:w-2/5 p-4 opacity-70">
        <div className="bg-gray-900 text-white border-white border-double border-4 rounded-lg p-4 ">
          <div className="grid grid-rows-3 grid-flow-col">
            <p className="row-span-1">network error</p>
            <p className="row-span-1">→ { msg }</p>
            <p className="row-span-1">→ try reload</p>
            <button
              className="text-white text-xl hover:scale-150 row-span-3"
              onClick={ closeCustomMessageWindow }
            >{ batsu }</button>
          </div>
        </div>
      </div>
    </>
  )
}

function closeCustomMessageWindow() {
  document.getElementById("error_message").remove();
}

