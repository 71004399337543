import { reqSpaceData } from "../../components/Firebase";
import SpaceManagedClass from "../../components/SpaceManagedClass";
import { unescapeProcess } from "../../components/Utils";
import React, { useState, useEffect } from "react";
import MenuVisitor from "../../components/MenuMySpace";
import { initItemsSpace, ITEMS_SPACE } from "../../components/InitItemsSpace";
import { getMonacardInfoMany, getAssetsBalancesElectrum } from "../../components/Mpurse";
import SeoMySpace from "../../components/SeoMySpace";
import { customMessageWindow } from "../../components/CustomMessege";
import { checkServerResponse } from "../../components/MonapartyAPI";

const MySpace = ({ serverData }) => {
  const [ MESSAGE_WINDOW, set_MESSAGE_WINDOW ] = useState(<></>);
  
  useEffect(() => {
    initItemsSpace("myspace");
    initFunc();
  }, []);

  async function initFunc() {
    ITEMS_SPACE.monaparty_server_url = await checkServerResponse();
    const SPACE_NAME: string = serverData.spacename;
    const USER_ADDRESS: string = serverData.addr;

    // イベント用
    if(USER_ADDRESS === "MPRf7kFGucUsK6DdBr5qP2a74sKQFY7Rik") returnHome();
    
    if(!checkAddressFormat(USER_ADDRESS)) return;
    await reqSpaceData(USER_ADDRESS);
    await setCurrentSpaceFromSpaceName(SPACE_NAME);
    if(ITEMS_SPACE.currentSpaceData === null) {
      returnHome();
      return;
    }
    const hasAssets = await getAssetsBalancesElectrum(USER_ADDRESS)
    if(hasAssets === undefined) {
      set_MESSAGE_WINDOW(customMessageWindow(hasAssets, 10));
    } else {
      checkAddressHasAssets(hasAssets);
      const joinedAssetListArray = [ ITEMS_SPACE.currentSpaceData.req_monacard_names ];
      const monacardList = await getMonacardInfoMany(joinedAssetListArray);
      if(monacardList[0] === undefined) {
        set_MESSAGE_WINDOW(customMessageWindow(monacardList[0], 11));
      } else {
        setMonacardInfo(monacardList);
      }
    }
  }
  
  return (
    <div className="m-0 overflow-hidden">
      <SeoMySpace props={ serverData } />
      { MESSAGE_WINDOW }
      <div className="z-50 h-screen">
        <MenuVisitor />
      </div>
      <SpaceManagedClass />
    </div>
  );
}

function checkAddressFormat(address: string) {
  if(address.slice(0, 1) === "M" && address.length === 34) return true;
  if(address.slice(0, 5) === "mona1" && address.length === 44) return true;
  returnHome();
  return false;
}

/**
 * check if a registered address still has assets.
 * Replace assets if address dose not have assets
 * @param address 
 */
function checkAddressHasAssets(hasAssets) {
  const resultAssets = [];
  const resisteredAssets = [];

  // 登録アドレスが現在所持しているアセットを格納
  hasAssets.forEach((r) => {
    if(r.asset_longname === null) {
      resultAssets.push(r.asset);
    } else {
      resultAssets.push(r.asset_longname);
    }
  });

  // 登録済みのアセットデータを抜き出す
  ITEMS_SPACE.currentSpaceData.picture_data.forEach((data) => {
    if(data.asset_name === "none") return;
    resisteredAssets.push(data.asset_name);
  });

  // 所持アセットの中に登録済みアセットがあるか確認する
  resisteredAssets.forEach((resisteredAssetName) => {

    // 所持している場合はリターン
    if(resultAssets.includes(resisteredAssetName)) return;

    // 所持していない場合は"notOwn"に置き換える
    ITEMS_SPACE.currentSpaceData.picture_data.find((currentData) => {
      if(currentData.asset_name === resisteredAssetName) {
        currentData.asset_name = "notOwn";
      }
    });
  });
}

/**
 * find and set a space name
 * @param spaceName
 */
async function setCurrentSpaceFromSpaceName(spaceName: string) {
  const currentSpace = ITEMS_SPACE.spaceData.space.find((r) => r.space_name === spaceName);
  if(currentSpace === undefined || currentSpace === null) {
    ITEMS_SPACE.currentSpaceData = null;
  } else {
    ITEMS_SPACE.currentSpaceData = currentSpace;
    ITEMS_SPACE.currentSpaceData.space_name = unescapeProcess(ITEMS_SPACE.currentSpaceData.space_name);
    ITEMS_SPACE.currentSpaceData.description = unescapeProcess(ITEMS_SPACE.currentSpaceData.description);
    ITEMS_SPACE.currentSpaceData.space_type = unescapeProcess(ITEMS_SPACE.currentSpaceData.space_type);
    ITEMS_SPACE.currentSpaceData.owner_name = unescapeProcess(ITEMS_SPACE.currentSpaceData.owner_name);
    ITEMS_SPACE.spaceData.user_address = unescapeProcess(ITEMS_SPACE.spaceData.user_address);
    ITEMS_SPACE.selectedSpaceType = ITEMS_SPACE.currentSpaceData.space_type;
  }
}

function returnHome() {
  const cut = window.location.href.indexOf("myspace");
  window.location.href = location.href.slice(0, cut);
}

function setMonacardInfo(monacardList) {
  
  for(let i = 0; i < monacardList.length; i++) {
    for(let ii = 0; ii < monacardList[i].length; ii++) {

      ITEMS_SPACE.monacardInfoCurrentlyOwned.push(
        {
          assetName: monacardList[i][ii].asset_longname === null ? monacardList[i][ii].asset_common_name: monacardList[i][ii].asset_longname,
          asset: monacardList[i][ii].asset,
          assetgroup: monacardList[i][ii].assetgroup,
          card_name: unescapeProcess(monacardList[i][ii].card_name),
          owner_name: unescapeProcess(monacardList[i][ii].owner_name),
          // imgur_url: unescapeProcess(monacardList[i][ii].imgur_url),
          imgur_url_original: ITEMS_SPACE.url.URL_IMG_DATA + monacardList[i][ii].cid + "l",
          imgur_url_preview: ITEMS_SPACE.url.URL_IMG_DATA + monacardList[i][ii].cid + "t",
          add_description: unescapeProcess(monacardList[i][ii].add_description),
          tw_id: unescapeProcess(monacardList[i][ii].tw_id),
          tw_name: unescapeProcess(monacardList[i][ii].tw_name),
          tag: unescapeProcess(monacardList[i][ii].tag),
          cid: monacardList[i][ii].cid,
          ver: monacardList[i][ii].ver,
          is_good_status: monacardList[i][ii].asseis_good_statustgroup,
          regist_time: monacardList[i][ii].regist_time,
          update_time: monacardList[i][ii].update_time,
        }
      );
    }
  }
}

export default MySpace;

// for SSR
export async function getServerData(context) {
  const spacename = context.query.spacename;
  const addr = context.query.addr;
  const time = context.query.time;
  const data = {
    spacename: spacename,
    addr: addr,
    time: time,
  }
  return { props : data }
}