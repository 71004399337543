import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Emoji, setArrowButtonEvent, arrowButton } from "./Utils";
import { ITEMS_SPACE } from "./InitItemsSpace";
import { manager } from "../components/ObjectLoaderSpace";
import { getDispensersMP, getAssetsInfo, sendAssetMP } from "../components/Mpurse";
import { Link } from "gatsby";

enum MenuCategory { loading, initCategory };

let doneLoading = false;
let reqDispenserAssetName = "";

const MenuMySpace = () => {

  const [ stateSpaceInfoWindow, setStateSpaceInfoWindow] = useState(false);
  const [ stateMonacardInfoWindow, setStateMonacardInfoWindow] = useState(false);
  const [ menuCategoryState, setCategoryState ] = useState(MenuCategory.loading);

  useEffect(() => {
    setTimeout(() => { if(!doneLoading) location.reload() }, 20000);
  }, []);

  manager.onLoad = () => {
    setCategoryState(MenuCategory.initCategory);
    document.getElementById("canvas").style.visibility = "visible";
    setArrowButtonEvent(ITEMS_SPACE.worldType);
    doneLoading = true;
  }

  /**
   * KeyMenu Switch
   * ON === true, OFF === false
   */
  const [ arrowMenu, setArrowMenu ] = useState("ON");
  const [ arrowWindowState, setArrowWindowState ] = useState(true);
  function setKeyMenuSwitch() {
    if(arrowWindowState) {
      setArrowMenu("OFF");
      setArrowWindowState(false);
    } else {
      setArrowMenu("ON");
      setArrowWindowState(true);
      setTimeout(() => {
        setArrowButtonEvent(ITEMS_SPACE.worldType);
      }, 50);
    }
  }

  const SpaceInfoWindow = () => {
    if(!stateSpaceInfoWindow) return <></>;
    if(ITEMS_SPACE.currentSpaceData != null) {
      const tweetURL = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(ITEMS_SPACE.currentSpaceData.space_name + " / " + ITEMS_SPACE.currentSpaceData.owner_name + " \n#monanosu_space\n" + location.href);
      const shareURL = location.href; // dont change
      return (
        <div id="menuField" className="z-20 break-all absolute bottom-0 right-0 sm:w-1/2 lg:w-1/3 w-full h-screen opacity-90 bg-gradient-to-r from-white/90 to-white/70 sm:from-white/80 sm:to-white/50 rounded-tl-3xl px-4 py-4 font-black overflow-auto">
          <div className="p-2">
            <p className="mb-4 text-3xl font-medium">SPACE INFO</p>
            <div className={ div_A }>
              <div className={ div_B }>SPACE NAME</div>
              <div id="space_name" className="">{ ITEMS_SPACE.currentSpaceData.space_name }</div>
            </div>
            <div className={ div_A }>
              <div className={ div_B }>DESCRIPTION</div>
              <div id="description" className="">{ ITEMS_SPACE.currentSpaceData.description }</div>
            </div>
            <div className={ div_A }>
              <div className={ div_B }>SPACE TYPE</div>
              <div id="space_type" className="">{ ITEMS_SPACE.currentSpaceData.space_type }</div>
            </div>
            <div className={ div_A }>
              <div className={ div_B }>OWNER NAME</div>
              <div id="owner_name" className="">{ ITEMS_SPACE.currentSpaceData.owner_name }</div>
            </div>
            <div className={ div_A }>
              <div className={ div_B }>OWNER ADDRESS</div>
              <div id="owner_adderss" className="">{ ITEMS_SPACE.spaceData.user_address }</div>
            </div>
          </div>
          <div className="p-2">
            <p className="mb-4 text-3xl font-medium">SHARE</p>
            <div className="flex flex-row">
              <input id="my_url" readOnly={ true } className="select-all w-full mr-2 px-2 rounded-lg border-2 border-solid border-black font-black" type="text" name="my_url" placeholder="My URL" value={ shareURL }></input>
              <a
                className="w-10 opacity-80"
                href={ tweetURL }
              >
                <StaticImage className="-z-10" src="../images/x-logo-white.png" alt="tweet" />
              </a>
            </div>
          </div>
          <div className="p-2 mt-4">
            <p className="mb-4 text-3xl font-medium">SETTING</p>
            <div className="flex flex-row">
              <span className="w-full">{ Emoji.starEmoji } KEY ICON</span>
              <button onClick={ setKeyMenuSwitch } className="bg-white mb-2 py-4 rounded-lg border-2 border-solid border-black w-1/3 sm:w-1/2 hover:bg-gray-200 font-black">{ arrowMenu }</button>
            </div>
          </div>
          <Link to="/space">
            <button className="bg-white rounded-lg border-2 border-solid border-black w-full py-8 hover:bg-stone-200 font-black mb-4">
              SPACE HOME
            </button>
          </Link>
          <button onClick={ () => setStateSpaceInfoWindow(false) } className="bg-black text-white rounded-lg border-2 border-solid border-black w-full py-8 hover:bg-stone-200 font-black">CLOSE</button>
        </div>
      );
    }
  }

  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();
  ITEMS_SPACE.handleMouseMove = handleMouseMove; // SpaceManagedClassのuseEffect内で使用

  /**
   * Processing when mousse ckick
   * @param event
   * @returns void
   */
  function handleMouseMove(event) {
    if(ITEMS_SPACE.monacardInfoCurrentlyOwned === null) return;
    const element = event.currentTarget;
    const x = event.clientX - element.offsetLeft;
    const y = event.clientY - element.offsetTop;
    const w = element.offsetWidth;
    const h = element.offsetHeight;

    mouse.x = (x / w) * 2 - 1;
    mouse.y = -(y / h) * 2 + 1;

    raycaster.setFromCamera(mouse, ITEMS_SPACE.littleMonaCamera);
    const intersects = raycaster.intersectObjects(ITEMS_SPACE.scene.children);
    if(intersects.length <= 0) return;

    updateMonacardInfo(intersects[0].object.name);
  }

  /** Monacard の情報を格納 */
  const infoObj = {
    card_name: "",
    asset: "",
    asset_name: "",
    description: "",
    owner_name: "",
    cid: "",
    regist_time: "",
    update_time: "",
    assetgroup: "",
    nft: "",
    monacardLink: "",
    imgur_url: "",
  }

  const [ info, setInfo ] = useState(infoObj);
  const rotateState = useRef(null);
  /**
   * Monacard Info Menu の更新
   * @param name
   */
  function updateMonacardInfo(name: string) {

    setStateMonacardInfoWindow(false); // メニュー更新 + CardBox以外をクリック時にメニューを閉じる

    let clickAssetName: string;
    for(let i = 0; i < ITEMS_SPACE.currentSpaceData.picture_data.length; i ++){
      if(name === ITEMS_SPACE.currentSpaceData.picture_data[i].picture_name) {
        clickAssetName = ITEMS_SPACE.currentSpaceData.picture_data[i].asset_name;
        break;
      }
    }

    for(let i = 0; ITEMS_SPACE.monacardInfoCurrentlyOwned.length > i; i++) {
      if(ITEMS_SPACE.monacardInfoCurrentlyOwned[i].assetName != clickAssetName) continue;
      try {

        const isNFT = (ITEMS_SPACE.monacardInfoCurrentlyOwned[i].assetgroup != null) ? true : false;
        rotateState.current = ITEMS_SPACE.monacardInfoCurrentlyOwned[i].add_description.includes("OPT_ROT");

        let dateTime1 = new Date(Number(ITEMS_SPACE.monacardInfoCurrentlyOwned[i].regist_time) * 1000).toString();
        let dateTime2 = new Date(Number(ITEMS_SPACE.monacardInfoCurrentlyOwned[i].update_time) * 1000).toString();

        infoObj.card_name = ITEMS_SPACE.monacardInfoCurrentlyOwned[i].card_name;
        infoObj.asset_name = ITEMS_SPACE.monacardInfoCurrentlyOwned[i].assetName;
        infoObj.asset = ITEMS_SPACE.monacardInfoCurrentlyOwned[i].asset;
        infoObj.description = ITEMS_SPACE.monacardInfoCurrentlyOwned[i].add_description;
        infoObj.owner_name = ITEMS_SPACE.monacardInfoCurrentlyOwned[i].owner_name;
        infoObj.cid = ITEMS_SPACE.monacardInfoCurrentlyOwned[i].cid;
        infoObj.regist_time = dateTime1;
        infoObj.update_time = dateTime2;
        isNFT ? infoObj.assetgroup = ITEMS_SPACE.monacardInfoCurrentlyOwned[i].assetgroup : infoObj.assetgroup = "";
        isNFT ? infoObj.nft = "NFT" : infoObj.nft = "NOT NFT";
        infoObj.monacardLink = "https://card.mona.jp/explorer/card_detail?asset=" + ITEMS_SPACE.monacardInfoCurrentlyOwned[i].assetName;
        
        // gif_listからgifか判定
        if(ITEMS_SPACE.gifCardList.includes(ITEMS_SPACE.monacardInfoCurrentlyOwned[i].cid)) {
          infoObj.imgur_url = ITEMS_SPACE.monacardInfoCurrentlyOwned[i].imgur_url_original.slice(0, -1);
        } else {
          infoObj.imgur_url = ITEMS_SPACE.monacardInfoCurrentlyOwned[i].imgur_url_original;
        }
        
        setInfo(infoObj);

        const asset = ITEMS_SPACE.monacardInfoCurrentlyOwned[i].asset;
        if(reqDispenserAssetName !== asset) {
          reqDispenserAssetName = asset;
          setDispensesInfoState(false);
        }
        
        setStateMonacardInfoWindow(true);
        break;
      }
      catch{}
    }
  }

  /**
   * Operation MenuIcon display
   * @returns MenuIcon HTML
   */
  const MenuIcons = () => {
    return (
      <div className="z-10 absolute bottom-0 right-0 sm:w-[10%] lg:w-[8%] xl:w-[6%] w-1/6 p-2 opacity-70">
        <button onClick={ () => setStateSpaceInfoWindow(true) }>
          <StaticImage src="../images/menubar.png" alt="menu" />
        </button>
      </div>
    );
  }

  const div_A = "px-4 py-6 mb-4 rounded-lg border-2 border-solid border-gray-900";
  const div_B = "text-gray-700 underline";

  const ImageHTML = () => {
    if(rotateState.current) {
      return (
        <div className="px-4 flex justify-center items-center">
          <img className="origin-center -rotate-90 w-3/4" src={ info.imgur_url } />
        </div>
      );
    } else {
      return (
        <div className="mb-4">
          <img src={ info.imgur_url } />
        </div>
      );
    }
  }

  /**
   * Processing when MonacardBox is pressed.
   * @returns Monacard Info Wndow HTML
   */
  const MonacardInfoWindow = () => {
    if(!stateMonacardInfoWindow) return <></>;
    return (
      <>
        <div id="monacardInfoWindow" >
          <div className="z-20 absolute bottom-0 right-0 sm:w-1/2 lg:w-1/3 w-full h-screen opacity-100 p-4 pt-20 sm:pt-4 bg-gradient-to-r from-white/90 to-white/70 sm:from-white/80 sm:to-white/50 overflow-auto rounded-lg font-black">
            <div className="mt-4 break-all">
              <div id="card_url">
                <ImageHTML />
              </div>
              <DispensesInfo />
              <div className={ div_A }>
                <div className={ div_B }>CARD NAME</div>
                <div id="card_name" className="text-sm">{ info.card_name }</div>
              </div>
              <div className={ div_A }>
                <div className={ div_B }>ASSET NAME</div>
                <div id="asset_name" className="text-sm">{ info.asset_name }</div>
              </div>
              <div className={ div_A }>
                <div className={ div_B }>ASSET GROUP</div>
                <div id="assetgroup" className="text-sm">{ info.assetgroup }</div>
                <div id="nft" className="text-sm">{ info.nft }</div>
              </div>
              <div className={ div_A }>
                <div className={ div_B }>DESCRIPTION</div>
                <div id="description" className="text-sm">{ info.description }</div>
              </div>
              <div className={ div_A }>
                <div className={ div_B }>OWNER NAME</div>
                <div id="owner_name" className="text-sm">{ info.owner_name }</div>
              </div>
              <div className={ div_A }>
                <div className={ div_B }>CARD URL</div>
                <div id="imgur_url" className="text-sm">{ info.imgur_url }</div>
              </div>
              <div className={ div_A }>
                <div className={ div_B }>IPFS CID</div>
                <div id="cid" className="text-sm">{ info.cid }</div>
              </div>
              <div className={ div_A }>
                <div className={ div_B }>Registration Date</div>
                <div id="regist_time" className="text-sm">{ info.regist_time }</div>
              </div>
              <div className={ div_A }>
                <div className={ div_B }>Update Date</div>
                <div id="update_time" className="text-sm">{ info.update_time }</div>
              </div>
              <a id="monacardLink" href={ info.monacardLink } className="text-sm after:content-['_↗']" target="_blank">Monacard</a>
              <button onClick={ () => setStateMonacardInfoWindow(false) } className="font-black bg-white rounded-lg border-2 border-solid border-black w-full hover:bg-stone-200 mt-2 py-8">CLOSE</button>
            </div>
          </div>
        </div>
      </>
    );
  }

  const [ dispensesInfoState, setDispensesInfoState ] = useState(false);
  const [ dispensesInfoHTML, setDispensesInfoHTML ] = useState(<></>);
  async function reqDispenserInfo() {

    document.getElementById("req_dispenser_button").style.backgroundColor = "rgb(107 114 128)";
    const assetInfo = await getAssetsInfo([ reqDispenserAssetName ]);
    const isDivisible = assetInfo[0].divisible;
    const data = await getDispensersMP(reqDispenserAssetName);
    document.getElementById("req_dispenser_button").style.backgroundColor = "black";

    if(data.length === 0) {
      setDispensesInfoHTML(
        <div className="text-sm mt-2 rounded-lg overflow-auto">
          <div>Not for sale.</div>
        </div>
      );
    } 
    else {
      const dispensesArray = [];
      let elementNum = 0;
      data.map((r) => {
        elementNum += 1;
        const id_1 = "dispenses_input_" + elementNum.toString();
        const id_2 = "dispenses_input_total_" + elementNum.toString();
        const source = r.source;
        let satoshirate: number;
        let giveQuantity: number;
        let giveRemaining: number;
        let escrowQuantity: number;

        if(isDivisible) {
          satoshirate = r.satoshirate / 1e8;
          giveQuantity = r.give_quantity / 1e8;
          giveRemaining = r.give_remaining / 1e8;
          escrowQuantity = r.escrow_quantity / 1e8;
        } else {
          satoshirate = r.satoshirate / 1e8;
          giveQuantity = r.give_quantity;
          giveRemaining = r.give_remaining;
          escrowQuantity = r.escrow_quantity;
        }
        const MAX_TOTAL = Math.trunc(giveRemaining / giveQuantity);
        const Dispenses_div = (props) => {
          return (
            <div className="p-1">
              <p className="underline">{ props.title }</p>
              <p className="p-2">{ props.data }</p>
            </div>
          );
        }
        dispensesArray.push(
          <div
            className="my-4 p-2 w-full rounded-lg border-2 border-black bg-white bg-opacity-90"
            key={ "dispenses_" + elementNum.toString() }
          >
            <Dispenses_div title="Source (販売元)" data={ source } />
            <Dispenses_div title="Price (1口の価格)" data={ satoshirate.toLocaleString() + " MONA" } />
            <Dispenses_div title="Give Quantity (1口の数量)" data={ giveQuantity.toLocaleString() } />
            <Dispenses_div title="Give Remaining (残りの数量)" data={ giveRemaining.toLocaleString() } />
            <Dispenses_div title="Escrow Quantity (売りに出された総量)" data={ escrowQuantity.toLocaleString() } />
            <div className="p-1">
              <p className="underline">BUY</p>
              <div className="flex justify-between p-2">
                <p className="mt-1">{ giveQuantity.toLocaleString() } × </p>
                <input
                  id={ id_1 }
                  className="w-20 px-2 py-1 rounded-lg border-2 border-black"
                  type="number"
                  min="1" max={ MAX_TOTAL } step="1"
                  defaultValue={ 1 }
                  onChange={
                    (e) => {
                      const el = (document.getElementById(id_2) as HTMLInputElement);
                      el.value = ((r.satoshirate / 1e8) * Number(e.target.value)).toString() + " MONA (TOTAL)";
                    }
                  }
                />
              </div>
              <p className="w-full text-right mb-1 px-4">MAX: { MAX_TOTAL }</p>
              <input
                className="text-right w-full my-1 px-2 py-1 bg-slate-100 rounded-lg border-2 border-black select-none"
                type="text" id={ id_2 }
                defaultValue={ ((r.satoshirate / 1e8)).toLocaleString() + " MONA (TOTAL)"}
              />
              <button
                className="bg-black text-white rounded-lg border-2 border-solid border-black w-full py-4 hover:bg-stone-200 font-black"
                onClick={ () => confirmDispenser(id_1, MAX_TOTAL, source, satoshirate, giveQuantity, giveRemaining, escrowQuantity) }
              >
                Mpurse
              </button>
            </div>
          </div>
        );
      })
      setDispensesInfoHTML(
        <div className="text-sm mt-2 h-[420px] rounded-lg overflow-auto">
          { dispensesArray }
        </div>
      );
    }
    setDispensesInfoState(true);
  }

  const dispenserData = useRef(null);
  const [ confirmDispenserWindowState, setConfirmDispenserWindowState ] = useState(false);
  function confirmDispenser(id: string, max: number, source: string, satoshirate: number, giveQuantity: number, giveRemaining: number, escrowQuantity: number) {
    const el = (document.getElementById(id) as HTMLInputElement);
    const REQ_QUANTITY = Number(el.value);
    
    if(max < REQ_QUANTITY) return;
    dispenserData.current = {
      id: id,
      source: source,
      satoshirate: satoshirate,
      giveQuantity: giveQuantity,
      giveRemaining: giveRemaining,
      escrowQuantity: escrowQuantity,
    }
    setConfirmDispenserWindowState(true);
  }

  const ConfirmDispenserWindow = () => {
    
    if(!confirmDispenserWindowState) return <></>;

    const el = (document.getElementById(dispenserData.current.id) as HTMLInputElement);
    const SATOSHI_RATE = dispenserData.current.satoshirate;
    const MONA_AMOUNT = SATOSHI_RATE * Number(el.value);
    const TOTAL_QUANTITY = dispenserData.current.giveQuantity * Number(el.value);
    const DESTINATION_ADDR = dispenserData.current.source;

    return (
      <div id="menuField" className="z-30 absolute inset-0 w-fit sm:w-full h-fit sm:h-full opacity-90 bg-gradient-to-r from-white to-neutral-300 rounded-tl-3xl p-4 sm:pt-4 font-black overflow-auto flex justify-center items-center">
        <div className="p-4 sm:p-8">
          <div className="p-1">
            <p className="underline">Source(販売元)</p>
            <p className="p-2">{ DESTINATION_ADDR }</p>
          </div>
          <div className="p-1">
            <p className="underline">Total Quantity(合計数量)</p>
            <p className="p-2">{ TOTAL_QUANTITY }</p>
          </div>
          <div className="p-1">
            <p className="underline">Total MONA(合計MONA)</p>
            <p className="p-2">{ MONA_AMOUNT }</p>
          </div>
          <div className="my-2 p-2">
            <div className="p-4 text-red-500 rounded-lg border-2 border-solid border-red-500 leading-relaxed">
              <p>・Please confirm the destination, asset, and transmission fee (MONA) on the Mpurse screen.</p>
              <p className="mb-4">・Please note that you will not receive the tokens if someone else purchased it first. Please note that we will not be able to return the MONA.</p>
              <p>・Mpurse画面にて宛先、アセット、送信料(MONA)をお確かめください。</p>
              <p>・他の人が先に購入した場合、トークンは貰えませんのでご注意ください。また、MONAの返却等の対応も出来ませんのでご了承ください。</p>
            </div>
            <div>{ dispenserTxHashHTML }</div>
          </div>
          <div className="flex">
            <button
              className="m-2 bg-white rounded-lg border-2 border-solid border-black w-full p-4 hover:bg-gray-200 font-black"
              onClick={ () => buyAsset(DESTINATION_ADDR, MONA_AMOUNT) }
            >
              BUY(OPEN Mpurse)
            </button>
            <button
              className="m-2 bg-black rounded-lg border-2 border-solid border-black w-full p-4 hover:bg-gray-200 font-black text-white"
              onClick={ () => setConfirmDispenserWindowState(false) }
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    );
  }

  const [ dispenserTxHashHTML, setDispenserTxHashHTML ] = useState(<></>);
  async function buyAsset(destinationAddr: string, amount: number) {
    // txhash, undefined が返る
    const result = await sendAssetMP("MONA", destinationAddr, amount);
    if(result === undefined) return;

    const boughtMessage = ITEMS_SPACE.currentSpaceData.space_name + " で " + info.owner_name + " さんの" + "「" + info.card_name + "」" + "を購入しました。\n#monanosu_space\n#monacard\n#monacoin\n" + location.href;

    const tweetText = encodeURIComponent(ITEMS_SPACE.currentSpaceData.space_name + " で " + info.owner_name + " さんの" + "「" + info.card_name + "」" + "を購入しました。\n#monanosu_space\n#monacard\n#monacoin\n" + location.href);
    const tweetURL = "https://twitter.com/intent/tweet?text=" + tweetText;

    setDispenserTxHashHTML(
      <div className="justify-center mt-8 mb-4 p-8 rounded-lg border-2 border-dashed border-black bg-white">
        <div className="flex justify-center my-4 p-4 rounded-lg border-2 border-dashed border-black">
          <a
            className="w-fit p-4 underline"
            href={ "https://mpchain.info/tx/" + result }
            target="_blank"
            rel="noopener noreferrer"
          >
            TX: { result }
          </a>
        </div>
        <div className="mt-8">SHARE</div>
        <div className="select-all break-all p-4 rounded-lg border-2 border-dashed border-black">
          { boughtMessage }
        </div>
        <div className="flex flex-row mt-4">
          <a
            className="w-10 opacity-80"
            href={ tweetURL }
          >
            <StaticImage className="-z-10" src="../images/x-logo-white.png" alt="tweet" />
          </a>
        </div>
      </div>
    );
  }

  const DispensesInfo = () => {
    if(!dispensesInfoState) {
      return (
        <button
          id="req_dispenser_button"
          className="w-full px-4 py-8 mb-4 rounded-lg border-4 border-double border-white text-white bg-black"
          onClick={ reqDispenserInfo }
        >
          CHECK SALES STATUS
        </button>
      );
    } else {
      return (
        <div className={ div_A }>
          <div className={ div_B }>DISPENSERS STATUS</div>
            { dispensesInfoHTML }
          {/* <div className="text-sm mt-2 h-[420px] rounded-lg overflow-auto">
            { dispensesInfoHTML }
          </div> */}
        </div>
      );
    }
  }

  /**
   * Switching the Menu Window
   */
  switch(menuCategoryState) {
    // ロード画面
    case MenuCategory.loading:
    return (
      <>
        <div id="waiting" className="animate-pulse w-full h-fit bg-gradient-to-t from-black via-blue-900 to-white">
          <div className="h-screen flex justify-center items-center">
            <div className="mt-6 text-xl text-center font-medium text-white">
              <p className="">...読込み中</p>
              <p className="">...loading</p>
              <p className="mt-2">上手く読み込めない場合は再度リロードしてみて下さい。</p>
            </div>
          </div>
        </div>
      </>
    );
    // メニュー初期画面
    case MenuCategory.initCategory:
    return (
      <>
        <div><MenuIcons /></div>
        <div><SpaceInfoWindow /></div>
        <div><MonacardInfoWindow /></div>
        <div><ConfirmDispenserWindow /></div>
        <div className="z-0 absolute bottom-0 left-0 mb-2 ml-0 sm:mb-4 sm:ml-2 opacity-70">
          <div>{ arrowButton(arrowWindowState) }</div>
        </div>
      </>
    );
  }
};

export default MenuMySpace;