import React from "react";
import { Helmet } from "react-helmet";
import { unescapeProcess } from './Utils';

function SeoMySpace(props) {
  // MEMO
  // { 
  //   props.props.spacename
  //     → TEST &lt;&gt;?__+*&quot;&quot;&#039;&#039;&amp;%$
  //     → escapeされたまま(db上と一致)
    
  //   unescapeProcess(props.props.spacename)
  //     → TEST <>?__+*""''&%$
  //     → ユーザー入力時と一致

  //   encodeURIComponent(props.props.spacename)
  //     → TEST%20%26lt%3B%26gt%3B%3F__%2B*%26quot%3B%26quot%3B%26%23039%3B%26%23039%3B%26amp%3B%25%24
  //     → ogp呼び出し時と一致
  // }

  const SPACE_NAME_FOR_TITLE = unescapeProcess(props.props.spacename);
  const SPACE_NAME_ESCAPE_FOR_OGP_LINK = encodeURIComponent(props.props.spacename);
  const USER_ADDRESS = props.props.addr;
  const TIMESTAMP = props.props.time;

  // console.log(props.props.spacename);
  // console.log(SPACE_NAME_FOR_TITLE);
  // console.log(SPACE_NAME_ESCAPE_FOR_OGP_LINK);

  const TITLE = SPACE_NAME_FOR_TITLE + " | monanosu space";
  const DESCRIPTION = "monanosu space";
  const SITE_URL = "https://monanosu.kotaroo.work/space/myspace/?spacename=" + SPACE_NAME_ESCAPE_FOR_OGP_LINK + "&addr=" + USER_ADDRESS + "&time=";
  const FAVICON = "https://monanosu.kotaroo.work/favicon.png";
  let OGP_URL: string;
  if(USER_ADDRESS === "MPRf7kFGucUsK6DdBr5qP2a74sKQFY7Rik") {
    OGP_URL = "https://firebasestorage.googleapis.com/v0/b/monalife.appspot.com/o/ogp_images%2F" + "ivent_ogp.png?alt=media";
  } else {
    OGP_URL = "https://firebasestorage.googleapis.com/v0/b/monalife.appspot.com/o/ogp_images%2F" + SPACE_NAME_ESCAPE_FOR_OGP_LINK + "_m_" + USER_ADDRESS + "_m_" + TIMESTAMP + ".png?alt=media";
  }

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <title>{ TITLE }</title>
      <meta name="description" content={ DESCRIPTION } />
      <link rel="shortcut icon" href={ FAVICON }></link>
      <meta property="og:url" content={ SITE_URL } />
      <meta property="og:title" content={ TITLE } />
      <meta property="og:image" content={ OGP_URL } />
      <meta property="og:description" content={ DESCRIPTION } />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={ TITLE } />
      <meta name="twitter:image" content={ OGP_URL } />
      <meta name="twitter:description" content={ DESCRIPTION } />
      <meta name="twitter:site" content="@monanosu" />
    </Helmet>
  )
}

export default SeoMySpace;
